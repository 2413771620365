import React from 'react';
// import gpt3Logo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Are you ready to experience Adult Prom Night?</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p><a href="https://hobbycards.vercel.app/">Adult Prom Night</a></p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        {/* <img src={gpt3Logo} alt="gpt3_logo" /> */}
        <p> <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4><a href="https://hobbyblog.vercel.app">Link s</a></h4>
        {/* <p>Overons</p> */}
        <p><a href="https://hobbycards.vercel.app">Private Sessions</a></p>
        {/* <p>Counters</p> */}
        <p><a href="https://hobbydutch.com/contact.html">Contact</a></p>
      </div>
      {/* <div className="gpt3__footer-links_div"><p><a href="https://hobbydutch.com/terms-of-use/">Terms & Conditions </a></p><p><a href="https://hobbydutch.com/privacy-policy/">Privacy Policy</a></p></div> */}
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        {/* <p>Crechterwoord K12 182 DK Alknjkcb</p>
        <p>085-132567</p> */}
        <p>info@hobbydutch.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 Hobbydutch Inc. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
