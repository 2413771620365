import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      {/* <h4>Request Early Access to Get Started</h4> */}
      <h1 className="gradient__text">The possibility of claiming the Prom Crown you desire is now within your reach!! </h1><br />
      <h2 className="gradient__text">Experience Latin and Ballroom dancing at our events. Book the event and engage in group sessions or ..</h2><br />
      <h2 className="gradient__text">Book both private classes and the event; enjoy lessons with a partner so you both feel confident on the dance floor.</h2><br />
      {/* <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p> */}
      <h4><a href="https://hobbydutch.com/contact.html">Request For Customized Dance Packages</a></h4>
    </div>
  </div>
);

export default Possibility;
