import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is Hobbydutch" text="Find a hobby, make new friends. We truly believe in this, because if someone is going to be your friend for the longterm, engaging in the activies you both like, can increase the chance of solid lasting friendship" />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities of pairing two people to share the cost of private classes and events at amazing ballrooms</h1>
      <p><a href="https://hobbyblog.vercel.app">Explore the Blog</a></p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Classes" text="We let two people, partners or couple try new hobbies by splitting the cost, while making friends. By sharing a class, you both pay less, making it a win-win for everyone!" />
      <Feature title="Home Owned Studios" text="Yes, you can turn your garage, studio, basement or backyard into a studio and rent it out for classes. That's exactly what our home owner partners are making possible." />
      <Feature title="Themed Parties" text="Every quarter, all participants and their partners get access to our mask or themed social parties. Social dances to expect include waltz, salsa, bachata, tango, swing, chacha and more." />
    </div>
  </div>
);

export default WhatGPT3;
