import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p><a href="https://hobbydutch.com/contact.html">Request For Customized Dance Packages</a></p>
      <h3>Register Today & explore the possibility of becoming Prom Queen or King!</h3>
    </div>
    <div className="gpt3__cta-btn">
      <button type="button"><a href="https://hobbycards.vercel.app/">Adult Prom Night</a></button>
    </div>
  </div>
);

export default CTA;
