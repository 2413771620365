import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gpt3__cta-content">Let&apos;s experience Adult Prom Night where partners showcase special dances on Valentine Weekend!</h1>
      <p>Get access to in-person classes, mask themed parties, downloadable tutorial videos and slack channels for ladies and gents. Pairing dance partners is guaranteed but based on a first come basis.</p>

      <div className="gpt3__header-content__input">
        {/* <input type="email" placeholder="Your Email Address" /> */}
        <button type="button"><a href="https://hobbycards.vercel.app/">Theme: Hollywood Night</a></button>
      </div>

      <div className="gpt3__header-content__people">
        <img src={people} />
        <p>Over 1000 people make friends when social dancing!</p>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
