import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Happy',
    text: 'The happiness after learning a new hobby is multiplied when shared with someone else, just as the difficult moments are made easier to bear.',
  },
  {
    title: 'Belonging',
    text: 'The feeling of belonging is beneficial because it makes us feel part of something, which helps to motivate us.',
  },
  {
    title: 'Easy Connection',
    text: 'It allows us to discover ourselves and increases connection.',
  },
  {
    title: 'More Social',
    text: 'It helps us to meet other people, to open up and to gain self-confidence to improve our social skills',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Why Partner Dancing?</h1>
      <p>Request For Customized Dance Packages</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
